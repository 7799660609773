<template>
  <div class="loading">
    <div class="loadingContent">
      <img src="@/assets/images/loading.svg" class="loadingLogo" />
      <p class="lead text-center mt-4">{{message}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    message: String,
    default: "Loading..."
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  .loadingContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
