const TRANSLATIONS = {
    error_title: "ผิดพลาด",
    error_info: "ดึงข้อมูลไม่สำเร็จ รบกวนลองใส่เลขแทร็คแล้วลองอีกครั้ง",
    loading: "Loading...",
    refresh: "โหลดอีกครั้ง",

    tracking_no: "เลขแทร็ค#",
    updated: "เปลี่ยนแปลงเมื่อ",

    official_tracking_url: "เลขแทร็ก",
    call_center_no: "เบอร์ติดต่อ"
}

export const trans = (key) => TRANSLATIONS[key];

