<template>
  <div id="errorPage">
    <div class="container">
      <div class="row justify-content-center">
        <!-- <div class="col-12 mt-48">
          <a href="#" class="text-dark btn p-0">
            <i class="fas fa-arrow-left"></i
          ></a>
        </div> -->
        <div class="col-10 text-center mt-136 mb-208">
          <img src="@/assets/images/error.svg" class="pb-2" />
          <h2 class="pt-4">{{ trans("error_title") }}</h2>
          <p class="pt-3">
            {{ trans("error_info") }}
          </p>

          <input
            type="text"
            class="form-control form-control-sm mb-2"
            style="width: 300px; margin: 0 auto"
            :placeholder="trans('tracking_no')"
            v-model="trackingNo"
          />

          <router-link
            :to="'/?barcode=' + trackingNo"
            class="btn btn-primary"
            >{{ trans("refresh") }}</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { trans } from "@/translations";

export default {
  name: "Error",
  data() {
    return {
      trackingNo: "",
    };
  },
  created() {},
  methods: {
    trans(key) {
      return trans(key);
    },
  },
};
</script>
