<template>
  <div id="informationPage">
    <div v-if="trackings">
      <div class="container">
        <img
          src="../assets/images/proship-ad.png"
          class="img-fluid mt-2"
          style="border-radius: 0.25rem"
        />

        <div class="row mb-0 mb-md-5 mt-3">
          <div class="col-12 col-md-8">
            <div
              class="
                d-flex
                flex-wrap
                justify-content-center justify-content-md-start
              "
            >
              <h2
                class="trackingNo text-center text-md-left align-self-center"
                style="font-size: 0.5rem"
              >
                {{ trans("tracking_no") }} {{ trackingNo }}
              </h2>
            </div>
          </div>

          <div class="col-12 col-md-4">
            <p
              class="updatedTime lead text-secondary text-center text-md-right"
            >
              <i class="fas fa-clock"></i>
              {{ trans("updated") }} {{ lastUpdatedAt }}
            </p>
          </div>
        </div>

        <div class="row mb-4 mt-n5">
          <div class="col-12">
            <img
              :src="smInfo.truckImg"
              :style="{
                height: '10rem',
                width: 'auto',
                marginLeft: `${progress}%`,
                transform: 'translate(-100%, 25%)',
              }"
            />
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                style="width: 100%"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body py-0 text-center">
          <div class="timeline-container position-relative" data-progress="25%">
            <ul class="timeline">
              <li
                class="event"
                :class="{ 'completed next-in-progress': index === 0 }"
                v-for="(dataItem, index) in trackings"
                :key="index + '-' + Date.now()"
              >
                <div class="row">
                  <div class="col-6 timestamp text-right px-4">
                    <h4>{{ dataItem.date }}</h4>
                    <p>{{ dataItem.time }}</p>
                  </div>
                  <div class="col-6 px-4 mb-3">
                    <h4>{{ dataItem.desc }}</h4>
                    <p>{{ dataItem.station }}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        class="d-flex justify-content-center mt-4 mb-4"
        v-if="shippingMethod"
      >
        <div>
          <div class="text-center">
            <img
              :src="require('../assets/images/' + shippingMethod + '_logo.png')"
              style="width: 8rem"
              class="mr-2 mb-2"
            />
          </div>
          <p class="text-center text-md-left" v-if="smInfo.callCenterNo">
            {{ trans("call_center_no") }}
            <a :href="'tel:+0' + smInfo.callCenterNo">
              {{ smInfo.callCenterNo }}
            </a>
          </p>
        </div>
      </div>

      <p class="text-center mt-5" style="font-size: 14px">
        PROSHIP - ระบบจัดการขนส่งเพื่อร้านค้าออนไลน์ ใช้งานฟรี สมัครได้ที่

        <a
          href="http://www.proship.co.th"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.proship.co.th
        </a>
      </p>

      <div class="text-center mb-4">
        <p class="text-center mt-2" style="font-size: 12px">
          *PROSHIP ไม่ได้เป็นผู้จำหน่ายสินค้า
          กรุณาติดต่อร้านค้าที่ท่านสั่งซื้อโดยตรง*
        </p>
      </div>
    </div>

    <Loading :message="trans('loading')" v-else />
  </div>
</template>

<script>
import moment from "moment";
import Loading from "@/components/Loading";
import { trans } from "@/translations";

export default {
  name: "Information",
  components: { Loading },

  created() {
    if (!this.$route.query.barcode) this.$router.push({ name: "Error" });
    this.$store.commit("setTrackingNo", this.$route.query.barcode);
    this.$store.dispatch("fetchTrackings", {
      callback: (status) => {
        if (!status) this.$router.push({ name: "Error" });
      },
    });
  },

  methods: {
    trans(key) {
      return trans(key);
    },
  },

  computed: {
    smInfo() {
      const INFO = {
        thaipost: {
          trackingPage: "https://track.thailandpost.co.th/",
          callCenterNo: "1545",
          truckImg: require("../assets/images/trucks/thaipost.png"),
        },

        eps: {
          trackingPage: "https://track.thailandpost.co.th/",
          callCenterNo: "1545",
          truckImg: require("../assets/images/trucks/thaipost.png"),
        },

        kerry: {
          trackingPage: "https://th.kerryexpress.com/th/track",
          callCenterNo: "1217",
          truckImg: require("../assets/images/trucks/kerry.png"),
        },

        jt: {
          trackingPage: "http://jtexpress.co.th/index/query/gzquery.html",
          callCenterNo: "02 009 5678",
          truckImg: require("../assets/images/trucks/jt.png"),
        },

        flash: {
          trackingPage: "https://www.flashexpress.com/tracking/",
          callCenterNo: "",
          truckImg: require("../assets/images/trucks/flash.png"),
        },

        scg: {
          trackingPage: "https://www.scgexpress.co.th/tracking",
          callCenterNo: "",
          truckImg: require("../assets/images/trucks/thaipost.png"),
        },

        shopee: {
          trackingPage: "https://spx.co.th/en/",
          callCenterNo: "",
          truckImg: require("../assets/images/trucks/shopee.png"),
        },
      };
      if (INFO[this.shippingMethod]) return INFO[this.shippingMethod];
      else return { trackingPage: "", callCenterNo: "" };
    },

    trackingNo() {
      return this.$store.state.trackingNo;
    },

    progress() {
      if ([1, 2, 3, 4].includes(this.$store.state.status)) {
        return this.$store.state.status * 25;
      }
      return 25;
    },

    shippingMethod() {
      return this.$store.state.shippingMethod;
    },

    trackings() {
      return this.$store.state.trackingsData;
    },

    lastUpdatedAt() {
      return this.trackings && this.trackings.length
        ? moment(this.trackings[this.trackings.length - 1].timestamp)
            .locale("th")
            .fromNow()
        : null;
    },
  },
};
</script>
